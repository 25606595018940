:root {
  --mit-purple-dark: #423565;
  --mit-purple: #423565;
  --grey-text: #1D1F23;  
  --title-text: 'Oligopoly', sans-serif;
  --font-text: 'Config', sans-serif;
}

@font-face {
  font-family: 'Oligopoly';
  src: url('./assets/fonts/Oligopoly-OTF/Oligopoly\ Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Config';
  src: url('./assets/fonts/config-rounded/Config\ Rounded\ Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

* {
  font-family: var(--font-text);
  color: var(--grey-text);
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

p {
  font-size: 1.2em;
}

h1, h2, h3 {
  font-family: var(--title-text);
  font-weight: bolder;
  color: var(--mit-purple);
}

.bg-mit {
  background-color: var(--mit-purple);
}

.text-mit {
  color: var(--mit-purple);
}

.text-mit-dark {
  color: var(--mit-purple-dark);
}

.center-align {
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

div .card-mit:nth-child(even) {
  background-color: var(--mit-purple);
}
div .card-mit:nth-child(odd) {
  background-color: var(--mit-purple-dark);
}